import React from "react";
import { useInfiniteQuery } from "react-query";
import { GetLinkList } from "../../../service/link";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import { theme } from "shared/src/theme";
import { Button } from "shared";
import { LinkCard } from "../../molecules";

const Wrapper = styled.div`
  width: 1440px;
  margin: 0 auto;
  @media (max-width: 1550px) {
    padding: 0px 5% 0px 5%;
  }
  @media (max-width: 1440px) {
    padding: 0px 9.02% 0px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 0px 5% 0px 5%;
  }
  @media (max-width: 1150px) {
    padding: 0px 3% 0px 3%;
  }
  @media (max-width: 768px) {
    padding: 0px 16px;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  background: ${theme.grayBackground};
  padding-bottom: 40px;
  & h3 {
    ${theme.typography.titleLarge}
    padding-top: 46px;
    margin-bottom: 40px;
    color: ${theme.textColor};
  }
  & .wrapper {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr 1fr 1fr;
    flex-wrap: wrap;
    @media (max-width: 1060px) and (min-width: 551px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 660px) {
      grid-template-columns: 1fr;
    }
  }
  & .titleContainer {
    color: ${theme.textColor};
    margin-bottom: 42px;
    padding-top: 46px;
  }
  @media (max-width: 768px) {
    & h3 {
      padding-top: 32px;
      margin-bottom: 24px;
    }
    & .titleContainer {
      text-align: center;
    }
    padding-bottom: 32px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  & button {
    padding: 0 16px;
  }
  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;

const LoadingWrapper = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const spreadArray = (data?: any[]): any[] => {
  const newArray: any[] = [];
  if (data) {
    for (const row of data) {
      const rowData = row?.data || [];
      for (const innerData of rowData) {
        newArray.push(innerData);
      }
    }
  }
  return newArray;
};

interface LinkProps {
  city?: any;
  initialData: any;
}
export const LinkCardList = ({ city, initialData }: LinkProps) => {
  const getCount = (allData) => {
    let sum = 0;
    for (const page of allData) {
      sum += page?.data?.length || 0;
    }
    return sum;
  };
  const { data, fetchNextPage, isLoading, isFetching } = useInfiniteQuery<
    any,
    any
  >(["fetchlinks", city], GetLinkList, {
    initialData: {
      pages: [{ ...initialData }],
      pageParams: [],
    },
    getNextPageParam: (lastPage: any, allData) => {
      const gotCount = getCount(allData);
      const totalCount = lastPage.count;
      if (gotCount < totalCount) {
        return allData.length + 1;
      }
    },
    refetchOnWindowFocus: false,
  });

  const { t } = useTranslation();

  return data?.pages[0]?.count != "0" ? (
    <Container>
      <Wrapper>
        <h3>{t("Links")}</h3>
        {isLoading ? (
          <LoadingWrapper>
            <Spin size="large" />
          </LoadingWrapper>
        ) : (
          <>
            <div className="wrapper">
              {data &&
                spreadArray(data?.pages)?.map((item, index) => (
                  <LinkCard
                    key={`links-${index}`}
                    logo={item?.logo}
                    title={item?.name}
                    description={item?.overview}
                    link={item?.url}
                  />
                ))}
            </div>
            {data?.pages[0]?.count != spreadArray(data?.pages)?.length && (
              <ButtonContainer>
                <Button
                  disabled={
                    data?.pages[0]?.count === spreadArray(data?.pages)?.length
                  }
                  loading={isFetching}
                  onClick={() => fetchNextPage()}
                  padding="0"
                  minheight={48}
                  minwidth={136}
                  type="primary"
                >
                  {t("Show More")}
                </Button>
              </ButtonContainer>
            )}
          </>
        )}
      </Wrapper>
    </Container>
  ) : (
    <></>
  );
};
