import { API, i18n } from "shared";

export const GetTopEventsList = async ({ queryKey }) => {
  const city = queryKey[1];
  return API.get("users/top-events", {
    params: {
      city: city,
      language: i18n.language?.startsWith("en") ? "en" : "ja",
    },
  });
};
